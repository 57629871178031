.toast-container{
  position: fixed;
  transform: translateX(-50%);
  left: 50%;
  bottom: 20px;
  z-index: 11000;

  .toast{
    background-color: #FFFFFF;
    border: 0;
    box-shadow: 0px 2px 4px 0px #0000003d;
    width: 350px;
    animation: 5s display infinite ;

    @media screen and (min-width: $desktop){
      width: 800px;
    }

    .bi{
      padding-right: 0.75rem
    }

    &.success{
      border-left: 5px solid #3CC13B;
      background-color: #F0FFF0;

      span{
        color: #3CC13B;
      }
    }
    &.danger{
      border-left: 5px solid #F03738;
      background-color: #FFF3F0;

      span{
        color: #F03738;
      }
    }
  }
}

@keyframes display {
  0% {opacity: 0;}
  2% {opacity: 0;}
  10% {opacity: 1;}
  90% {opacity: 1;}
  98% {opacity: 0;}
  100%{opacity: 0;}
}

/* Notifications */
.custom-alert{
  border-radius: 5px;
  padding: 16px;
  border: 1px solid;
  font-size: 14px;
  .bi{
    font-size: 30px;
  }
  .notification-p{
    margin-bottom: 0;
    &:not(:last-child){
      margin-bottom: 8px;
    }
  }
  &.info{
    border-color: #b6d4fe;
    background-color: #e7f1fe;
    border-left: 5px solid $primary-600;
    .text-info{
      color: $primary-600 !important;
    }
  }
  &.danger{
    border-color: #f5c2c7;
    background-color: #ffeef0;
    border-left: 5px solid $danger-600;
  }
  &.success{
    border-color: #badbcc;
    background-color: #dbece5;
    border-left: 5px solid $success-600;
  }
  &.warning{
    border-color: #ffecb5;
    background-color: #fff9e6;
    border-left: 5px solid $danger-300;
  }
  &.dark{
    border-color: #bcbebf;
    background-color: #e2e3e5;
    border-left: 5px solid $grey-950;
  }
}