//Custom
@import "reset";
@import "vars";
@import "auth";
@import "notifications";
@import "modals";
@import "forms";
@import "tables";
@import "cards";
@import "app";
@import "fonts";

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');

body {
  font-family: "Montserrat", sans-serif;
}

a {
  color: $primary-blue;
  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer;
}

.btn {
  //border-radius: 2px;

  &.btn-primary {
    background-color: $primary-blue;
    border-color: $primary-blue;
  }

  &.btn-secondary {
    background-color: $secondary-btn;
    border-color: $secondary-btn;
    color: #485b6f;
  }
}

.badge {
  &.badge-outline-success {
    border: 2px solid #33c14c;
    color: #33c14c;
    min-width: 70px;
  }

  &.badge-outline-error {
    border: 2px solid #dc3545;
    color: #dc3545;
    min-width: 70px;
  }

  &.badge-secondary {
    background-color: #6c757d;
    margin-right: 8px;
  }
}

.nav-tabs {

  .nav-link {
    border: 0;
    border-top: 5px solid transparent;
    border-radius: 0;
    font-weight: 500;
    color: $tab-link;

    &.active {
      background-color: transparent;
      border-top-color: $primary-blue;
      font-weight: 600;
      color: $tab-link-active;
    }
  }
}

.app-wrapper {
  .box {
    .nav-tabs {
      .nav-link {
        padding: 16px;
      }
    }
  }
}

.litepicker-btn {
  border: 1px solid $grey-200;
  border-radius: 5px;
  display: flex;
  padding: 8px 16px;
  gap: 8px;
  justify-content: center;
  width: fit-content;
  align-items: center;
  font-weight: 600;
  transition: background-color .15s, border-top-color .15s, border-right-color .15s, border-bottom-color .15s, border-left-color .15s;

  &:hover{
    background-color: $grey-50;
    border-color: $grey-400;
  }
}

.btn-orange {
  background-color: #fc7949;
  border-color: #fc7949;
  color: #fff;
}