.card-custom{
  border-radius: 4px;
  padding: 16px 24px;

  &.card-blue{
    background-color: #e8f1fd;
    color: #2565bd;
  }

  &.simple-card{
    border: 1px solid #bababa;
  }
}