//Font-size
.display-xxl {
  font-size: 72px!important;
  line-height: 90px!important;
}
.display-xl{
  font-size: 60px!important;
  line-height: 72px!important;
}
.display-lg{
  font-size: 48px!important;
  line-height: 60px!important;
}
.display-md{
  font-size: 36px!important;
  line-height: 44px!important;
}
.display-sm{
  font-size: 30px!important;
  line-height: 38px!important;
}
.display-xs{
  font-size: 24px!important;
  line-height: 32px!important;
}
.text-xl{
  font-size: 20px!important;
  line-height: 30px!important;
}
.text-lg{
  font-size: 18px!important;
  line-height: 28px!important;
}
.text-md{
  font-size: 16px!important;
  line-height: 24px!important;
}
.text-sm{
  font-size: 14px!important;
  line-height: 20px!important;
}
.text-xs{
  font-size: 12px!important;
  line-height: 18px!important;
}
.light{font-weight: 300!important;}
.regular{font-weight: 400!important;}
.medium{font-weight: 500!important;}
.semibold{font-weight: 600!important;}
.bold{font-weight: 700!important;}

.MuiTypography-root{
  font-family: "Montserrat",sans-serif !important;

  a{
    text-decoration: none;
    color: $primary-800;
  }
}