.genelead-app {
  min-height: 100vh;
  position: relative;
  background: $app-bg;

  @media screen and (min-width: $desktop) {
    display: grid;
    grid-template-areas: "nav head"
      "nav main";
    grid-template-columns: 160px calc(100vw - 160px); //scroll
    grid-template-rows: 72px auto;
  }

  nav {
    &.sidenav {
      grid-area: nav;
      width: auto;
      display: none;
      flex-direction: column;
      height: 100vh;
      position: sticky;
      top: 0;
      z-index: 1000;
      background-color: $primary-blue;
      box-shadow: 0 6px 10px rgba(0, 0, 0, .25);

      &.open {
        background-color: #FFFFFF;
        display: flex;

        .btn-close {
          right: 0;
          top: 10px;
        }
      }

      @media screen and (min-width: $desktop) {
        display: flex;
      }

      &>* {
        padding: 10px 0px;
      }

      .nav-head {
        align-items: center;
        display: flex;
        flex-direction: column;

        .logo {
          width: 100%;
          padding: 0 20px;
        }
      }

      //.dashboard-link{
      //  //background-color: $app-bg;
      //  border-radius: 6px;
      //  color: $primary-blue;
      //  display: flex;
      //  padding: 4px 10px;
      //
      //  .name{
      //    font-size: 17px;
      //    font-weight: 700;
      //  }
      //
      //  &.active{
      //    background-color: #1A75FF1A;
      //    color: $primary-blue;
      //  }
      //}
      //
      //.accordion{
      //
      //  .nav-section{
      //    border: 0;
      //    background-color: transparent;
      //
      //    .nav-section-title{
      //
      //      button{
      //        padding: 7px;
      //        border-radius: 6px;
      //        background-color: transparent;
      //        font-size: 17px;
      //        font-weight: 700;
      //
      //        &:not(.collapsed){
      //          box-shadow: none;
      //          //background: #1a75ff1a;
      //          color: $primary;
      //        }
      //
      //        &:focus{
      //          box-shadow: none;
      //        }
      //      }
      //    }
      //
      //    .accordion-body{
      //      padding: 0;
      //
      //      .nav-link{
      //        font-size: 14px;
      //        margin-left: 30px;
      //        padding: 8px 0;
      //
      //        .name{
      //          font-size: 14px;
      //          font-weight: 400;
      //        }
      //
      //        &:hover{
      //          color: $primary-blue;
      //        }
      //
      //        &:last-child{
      //          padding: 8px 0 24px;
      //        }
      //
      //        &.active{
      //          background-color: transparent;
      //        }
      //      }
      //    }
      //  }
      //}
      //.disabled{
      //  pointer-events: none;
      //  opacity: .3;
      //}

      .side-menu {
        a {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          text-align: center;
          font-size: 12px;
          color: #fff;
          opacity: 0.5;
          margin-top: 10%;
          text-decoration: none;
          padding: 16px;

          &:hover {
            opacity: 1;
          }

          &.active {
            opacity: 1;
            font-weight: 700;
            background: linear-gradient(180deg, #F7F9FF19 0%, #F0F4FE19 97.92%);
          }

          &>span {
            &.bi {
              font-size: 40px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }

  header {
    display: flex;
    justify-content: end;
    align-items: start;
    padding: 15px;

    @media screen and (min-width: $tablet-md) {
      padding: 20px 28px;
    }

    @media screen and (min-width: $desktop) {
      grid-area: head;
    }

    button {

      &.btn-header {
        background-color: transparent;
        background-image: none;
        border-color: $primary-blue;
        border-radius: 6px;
        color: $primary-blue;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        cursor: pointer;
        font-size: 14px;
        padding: .35rem .75rem;

        &:hover {
          background-color: $primary-blue;
          color: #ffffff;
        }

        &.btn-icon {
          height: 46px;
          width: 46px;
          padding: 9px;

          .bi {
            font-size: 27px;
            line-height: 1;

            @media screen and (min-width: $tablet-md) {
              font-size: 22px;
            }
          }

          @media screen and (min-width: $tablet-md) {
            height: 44px;
            width: 44px;
            padding: 10px;
          }
        }

        &.no-carret {
          &::after {
            display: none;
          }
        }

        &+.user-dropdown,
        &+.customer-help {
          border-radius: 6px;
          box-shadow: 0 2px 4px 0 #00000040;
          right: 0;
          top: calc(100% + 5px);
          width: 260px;
          border: 0;
          padding: 0;
          font-size: 14px;

          .user-dropdown-head {
            padding: 18px 13px;
            text-align: left;
          }

          .dropdown-divider {
            margin: 0;
          }

          .bi {
            font-size: 20px;
            line-height: 1;
            color: #c2c9d1;
          }

          .link-danger {

            .bi {
              color: $danger-600;
            }

            &:active {
              background-color: $danger-700;
              color: white;
              border-bottom-left-radius: 6px;
              border-bottom-right-radius: 6px;

              .bi {
                color: white;
              }
            }
          }
        }

        &.customer-site-selector {
          height: 46px;
          font-size: 14px;

          img {
            margin-bottom: -4px;
          }

          &+.site-dropdown {
            width: 100%;
            max-height: 200px;
            overflow-x: auto;
            border-radius: 6px;
            box-shadow: 0 2px 4px 0 #00000040;
            right: 0;
            top: calc(100% + 5px);
            border: 0;
            padding: 0;

            .dropdown-item {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  main {
    @media screen and (min-width: $desktop) {
      grid-area: main;
    }

    .app-wrapper {
      padding: 0 24px 48px;

      .box {
        background-color: white;
        border-radius: 4px;
        margin-bottom: 25px;

        .box-head,
        .box-body,
        .box-footer {
          padding-left: 24px;
          padding-right: 24px;
        }

        .box-head {
          align-items: center;
          border-bottom: 1px solid #d6d6d6;
          display: flex;
          justify-content: space-between;
          min-height: 60px;
        }

        .box-body,
        .box-footer {
          padding-top: 24px;
          padding-bottom: 24px;
        }

        .box-footer {
          border-top: 1px solid #d6d6d6;
          display: flex;
          justify-content: end;
        }
      }
    }
  }

  .MuiTableRow-head{
    .MuiTableCell-head{
      background-color: $primary-600;
      color: white;
    }
  }
}