#auth-wrapper{
  height: 100vh;
  background: url("../img/login-v2.png");
  background-size: cover;
  background-repeat: no-repeat;

  #auth-box{
    background-color: white;
    border-radius: 8px;
    padding: 32px 24px;

    img{
      height: 45px;
      display: block;
      margin: 0 auto;
    }

    .auth-app-title{
      text-align: center;
      margin: 16px 0;
      color: $primary-600;
    }

    input{
      color: $grey-950;
      //padding: 10px 16px 10px 0;

      &::placeholder{
        color: $grey-400;
      }
    }

    fieldset{
      //border-radius: 8px;
      border-color: $grey-300;
    }

    .MuiButton-containedPrimary{
      display: flex;
      margin: 16px auto 0;
      box-shadow: none;
      text-transform: none;
      //padding: 10px 18px;
    }
  }
}

//#auth-container {
//  background: url("../img/login-v2.png");
//  height:100vh;
//  background-size: 100%;
//}
//
//
//.auth{
//  height: 100vh;
//  display: grid;
//  grid-template-columns: 1fr;
//  grid-template-rows: 1fr;
//  grid-template-areas: "auth-mobile";
//
//  @media screen and (min-width: $tablet-sm){
//    grid-template-columns: 3fr 1fr;
//    grid-template-areas: "auth-left auth-right";
//  }
//
//  .auth-left{
//    grid-area: auth-left;
//    display: none;
//    background-image: url(../../assets/img/login-bg.jpg);
//    background-size: cover;
//
//    @media screen and (min-width: $tablet-sm){
//      display: block;
//    }
//  }
//
//  .auth-right {
//    grid-area: auth-mobile;
//    display: flex;
//    flex-direction: column;
//    padding: 20px 40px 50px;
//
//    @media screen and (min-width: $tablet-sm) {
//      grid-area: auth-right;
//    }
//
//    .auth-logo{
//        height: 100px;
//        margin-bottom: 20px;
//    }
//
//    .auth-app-title{
//      border-left: 3px solid $purple-blue;
//      padding-left: 8px;
//      font-size: 18px;
//      margin-bottom: 24px;
//    }
//
//    .forgot-link{
//      font-size: .75rem;
//    }
//
//    a{
//      text-decoration: none;
//    }
//  }
//}