table {
  font-size: 90%;

  &.table {

    &> :not(:first-child) {
      border-top: none;
    }

    thead {
      th {
        background-color: $primary-blue;
        color: white;
        box-shadow: 0 1px 0 0 #DDDFEA;

        &:first-child {
          border-top-left-radius: 2px;
          border-bottom-left-radius: 2px;
        }

        &:last-child {
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
        }
      }
    }

    tbody {
      tr {

        &:hover {
          background-color: rgba(0, 0, 0, .05);

          td {
            color: #212529;
          }
        }

        td {
          font-weight: 500;
          font-size: 14px;
          color: #757575;
        }
      }
    }
  }

  &.table-reporting {
    tr {
      &:last-child {
        background-color: #E5F6EE;
      }
    }
  }
}