//Colors
$app-bg:linear-gradient(180deg,#f6f9ff,#f0f4fe);
$primary-blue: #072763;
$secondary-btn: #e9ecef;
$purple-blue: #5C6BC0;
$input-bg: #F4F5F9;
$tab-link: #757575;
$tab-link-active: #171717;

//Breakpoints
$mobile: 500px;
$tablet-sm: 768px;
$tablet-md: 900px;
$desktop: 1024px;
$desktop-lg: 1280px;
$desktop-xl: 1440px;

//FontSize
.fs-12{font-size: 12px;}
.fs-13{font-size: 13px;}
.fs-14{font-size: 14px;}
.fs-16{font-size: 16px;}
.fs-18{font-size: 18px;}
h1{font-size:24px;}
h2{font-size:18px;}
h3{font-size: 16px;}
.rgpd-date {font-size: 40px;}


// Colors
$app-bg: linear-gradient(to bottom, #f6f8fc, #f4f8fe);
$whitepanel: #fdfeff;
$whitepanel-gradient: linear-gradient(to bottom, $whitepanel 80%, #f4f8fe 100%);
$primary-50: #f0f6fe;
$primary-100: #ddeafc;
$primary-200: #c3dcfa;
$primary-300: #99c6f7;
$primary-400: #69a8f1;
$primary-500: #4687eb;
$primary-600: #316adf;
$primary-700: #2855cd;
$primary-800: #2747a6;
$primary-900: #223a7a;
$primary-950: #1b2850;
$danger-50: #fff3ec;
$danger-100: #ffe4d3;
$danger-200: #ffc5a5;
$danger-300: #ff9c6d;
$danger-400: #ff6732;
$danger-500: #ff3f0a;
$danger-600: #ff2300;
$danger-700: #cc1402;
$danger-800: #a1110b;
$danger-900: #82120c;
$danger-950: #460404;
$grey-50: #f6f7f9;
$grey-100: #eceef2;
$grey-200: #d5d9e2;
$grey-300: #afb8ca;
$grey-400: #8492ac;
$grey-500: #657492;
$grey-600: #505d79;
$grey-700: #424c62;
$grey-800: #394153;
$grey-900: #333947;
$grey-950: #1e212a;
$success-50: #ecfdf3;
$success-100: #d1fadf;
$success-200: #a6f4c5;
$success-300: #6ce9a6;
$success-400: #32d583;
$success-500: #21b76a;
$success-600: #039855;
$success-700: #027a48;
$success-800: #05603A;
$success-900: #054f31;

.secondary-300{color: $grey-300}
.secondary-500{color: $grey-500}